import React, { FunctionComponent, useEffect } from 'react'
import EnrollmentBody from '@/containers/EnrollmentBody'
import HeaderContainer from '@/containers/HeaderContainer'
import { useDispatch, useSelector } from 'react-redux'
import useQueryString from '../src/hooks/useQueryString'
import { loadFullUrl, loadQuery } from '@/state/tracking'
import Head from 'next/head'
import { useRouter } from 'next/router'
import * as ga from '../src/utils/ga'
import usePlan from '../src/hooks/usePlan'
import useActivation from '../src/hooks/useActivation'
import useResidentData from '../src/hooks/useResidentData'
import { SessionStore } from '@/constants'
import { parseQueryString } from '@/utils'
import { selectEnrollmentState, selectPlanState, selectResidentDataState } from '@/state/selectors'
import LoadingIndicator from '@/components/LoadingIndicator'
import FormProveInfo from '@/components/FormProveInfo'
import { cacheActivationInfoFromSession } from '@/state/activation'
import { cacheResidentDataFromSession } from '@/state/residentData'
import { cacheAccountInfo } from '@/state/enrollment'
import Footer from '@/components/Footer'

const Index: FunctionComponent = () => {
  const dispatch = useDispatch()
  const query = useQueryString()
  const router = useRouter()
  const { isProveWorkflow } = useSelector(selectEnrollmentState)
  const queryObject = parseQueryString(query.queryObject || {})
  const { details } = useSelector(selectPlanState)
  useResidentData(query.queryObject?.residentid)
  useActivation(query.queryObject?.activationkey)

  const planRequestObject = query.queryObject?.activationkey
    ? {
        effortCode: '',
        planCode: '',
        groupingCode: '',
      }
    : {
        effortCode: queryObject.effortCode,
        planCode: queryObject.planCode,
        groupingCode: queryObject.groupingCode,
      }
  usePlan(planRequestObject)

  useEffect(() => {
    if (!query.queryObject?.activationkey && sessionStorage?.getItem(SessionStore.ActivationInfo)) {
      dispatch(
        cacheActivationInfoFromSession(JSON.parse(sessionStorage?.getItem(SessionStore.ActivationInfo) as string)),
      )
    }

    if (!query.queryObject?.residentid && sessionStorage?.getItem(SessionStore.ResidentData)) {
      dispatch(cacheResidentDataFromSession(JSON.parse(sessionStorage?.getItem(SessionStore.ResidentData) as string)))
    }

    if (sessionStorage?.getItem(SessionStore.Account)) {
      const accountData = JSON.parse(sessionStorage?.getItem(SessionStore.Account) as string)
      dispatch(cacheAccountInfo({ ...accountData, isSaveOnly: true }))
    }
  }, [])

  const { isFetching: isResidentFetching } = useSelector(selectResidentDataState)
  let component

  if (!details || isResidentFetching) {
    component = <LoadingIndicator status="progress" title="Loading..." description="Please wait..." />
  } else if (details?.proveMFAEnabled && isProveWorkflow !== null) {
    component = <FormProveInfo />
  } else {
    component = <EnrollmentBody />
  }

  useEffect(() => {
    dispatch(loadQuery({ query }))
    dispatch(loadFullUrl({ url: window.location.href }))
    ga.pageView(router.pathname)
  }, [])

  return (
    <>
      <Head>
        <title>Create Your Account</title>
      </Head>
      <HeaderContainer />
      {component}
      <Footer />
    </>
  )
}

export default Index
