import React, { FunctionComponent } from 'react'
import { Images } from '@/constants'
import { InputAdornment, SxProps, Theme } from '@mui/material'

type Props = {
  sx?: SxProps<Theme>
  info?: boolean
}

const ValidInputIcon: FunctionComponent<Props> = ({ sx, info = false }: Props) => {
  return (
    <InputAdornment position="end" sx={sx}>
      <img src={info ? Images.InfoIcon : Images.GreenCheckmarkIcon} alt="" />
    </InputAdornment>
  )
}

export default ValidInputIcon
